<template>
	<div v-if="!componentType">
		Error: component '{{routingComponentName}}' not found
	</div>
	<component v-else :is="componentType" :global_style="currentLayout.global_style">
	</component>
</template>

<script>
	// TODO: make it to be functional component (check if possible)
	function debounce(func, wait) {
		let timeout;
		return function () {
			let context = this, args = arguments;
			let later = function () {
				timeout = null;
				func.apply(context, args);
			};
			if (!timeout) setTimeout(later, wait);
		};
	}

	export default {
		props: {
			routingComponentName: {
				required: true
			},
		},
		computed: {
			currentLayout() {
				return this.$root.$children[1];
			},
			componentType() {
				return (this.currentLayout
					&& this.currentLayout.components
					&& this.currentLayout.components[this.routingComponentName])
					|| null;
			},
		},
		async mounted() {
			if (process.client) {
				this.scrollBind = debounce(this.scrollListener.bind(this), 100);
				window.addEventListener('scroll', this.scrollBind);
				window.addEventListener('resize', this.scrollBind);
				await this.$nextTick();
				this.scrollBind();
			}
		},
		destroyed() {
			if (process.client) {
				window.removeEventListener('scroll', this.scrollBind);
				window.removeEventListener('resize', this.scrollBind);
			}
		},
		methods: {
			scrollListener() {
				let processScroll = (item_type, item) => {
					let rect = item.getBoundingClientRect();
					if (rect.top < -500 || rect.top > window.innerHeight + 500) return false;
					if (item_type === 'img-src') {
						item.setAttribute('src', item.getAttribute('data-lazy-img-src'));
						item.removeAttribute('data-lazy-img-src');
					}
					if (item_type === 'bg-img') {
						item.style.backgroundImage = 'url(\'' + item.getAttribute('data-lazy-bg-img') + '\')';
						item.removeAttribute('data-lazy-bg-img');
					}
				};
				for (let it of this.$el.querySelectorAll('[data-lazy-img-src]') || []) {
					processScroll('img-src', it)
				}
				for (let it of this.$el.querySelectorAll('[data-lazy-bg-img]') || []) {
					processScroll('bg-img', it)
				}
				// if (this.$el) {
				// 	console.log('Scroll:', this.kind, this.is_scroll_near);
				// 	let rect = this.$el.getBoundingClientRect();
				// 	this.is_scroll_near = this.is_scroll_near || (rect.top - window.innerHeight - 500 < 0);
				// }
			},
		}
	}
</script>


